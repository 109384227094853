.solution-hero {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: 10%;
    padding-left: 10%;
    gap: 146px;
}

.bubble-hero {
    padding: 6px 12px;
    border: 1px solid rgba(41, 11, 0, 0.08);
    background-color: rgba(41, 11, 0, 0.05);
    border-radius: 200px;
    display: inline-flex;
}

.solution-hero-title {
    font-size: 14px;
    font-weight: 500;
    font-family: 'SF Pro Display';
    line-height: 140%;
    letter-spacing: -0.36px;
    color: rgba(41, 11, 0, 0.8);
    margin: 0;
}

.solution-hero-p {
    font-size: 36px;
    font-weight: 500;
    font-family: 'SF Pro Display';
    line-height: 140%;
    width: 600px;
    color: rgba(41, 11, 0, 1);
}

.solution-hero-pp {
    font-size: 20px;
    font-weight: 400;
    line-height: 140%;
    color: rgba(41, 11, 0, 0.8);
}

.solution-hero-right {
    width: 100%;
}

.value-section {
    margin-top: 146px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 10%;
    margin-right: 10%;
    gap: 20px;
    height: 100vh;
}

.value-title {
    font-size: 16px;
    font-weight: 500;
    color: rgba(41, 11, 0, 1);
    line-height: 140%;
    margin: 0;
    letter-spacing: -0.36px;
}

.value-p {
    font-size: 36px;
    font-weight: 500;
    color: rgba(41, 11, 0, 0.8);
    line-height: 140%;
    margin: 0;
    letter-spacing: -0.56px;
    text-align: center;
    width: 80%;
}

.industry-wrapper-company {
    width: 100%;
    height: 100vh;
    margin-top: 146px;
    justify-content: center;
    align-items: center;
    padding-left: 10%;
    padding-right: 10%;
}

span {
    color: transparent;
}

.hightlight {
    color: transparent;
    display: hidden;
}