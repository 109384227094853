.footer-wrapper {
    width: 100vw;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    padding: 24px;
}

.footer-content-wrap {
    display: flex;
    flex-direction: row;
    margin-left: 3%;
    margin-right: 3%;
    justify-content: space-between;
    align-items: center;
}

.footer-logo svg {
    width: 120px;
}

.c-tag {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.56px;
    line-height: 140%;
    color: rgba(0, 0, 0, 0.5);
}

.login-footer {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.26px;
    color: rgba(41, 11, 0, 1);
}

.login-footer-wrap a {
    position: relative;
    padding-bottom: 6px;
    color: rgba(163, 50, 11, 1);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    transition: color 0.3s ease;
}

.login-footer-wrap a::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 1px;
    /* Initial border thickness */
    background-color: rgba(163, 50, 11, 1);
    transition: width 0.3s ease;
}

.login-footer:hover::after {
    width: 100%;
    /* Expands the bottom border smoothly */
}