.prop-section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.motion-class {
    min-height: 100vh;
    font-size: 48px;
    font-weight: 500;
    font-family: 'SF Pro Display';
    text-align: center;
    max-width: 50%;
    line-height: 160%;
    letter-spacing: -1.5px;
    color: rgba(255, 255, 255, 0.9);
}