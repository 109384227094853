.nav-reg-link {
    color: #1a1a1a;
}

.mid-nav-reg {
    display: flex;
    flex-direction: row;
    gap: 24px;
    justify-content: center;
    align-items: center;
}

.mid-nav-reg a {
    position: relative;
    padding-bottom: 6px;
    color: rgba(41, 11, 0, 1);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    transition: color 0.3s ease;
}

.mid-nav-reg a::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 1px;
    /* Initial border thickness */
    background-color: rgba(163, 50, 11, 1);
    transition: width 0.3s ease;
}

.nav-reg-link:hover::after {
    width: 100%;
}

.navbar-reg {
    position: fixed;
    top: 0;
    width: 100%;
    height: 90px;
    border: none;
    background-color: transparent;
    transition: background-color 0.3s ease, color 0.3s ease;
    z-index: 1000;
}

.navbar-reg {
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(20px);
    border: none;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.05);
}