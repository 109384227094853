.contact-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.contact-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 36px;
}

.contact-top h2 {
    font-size: 36px;
    line-height: 140%;
    margin: 0;
    text-align: center;
    margin-bottom: 36px;
    width: 100%;
}

.input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-bottom: 6px;
    width: 790px;
}

form {
    width: 790px;
    margin-left: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

form input {
    margin: 0;
    border-radius: 3px;
    background-color: transparent;
    font-size: 15px;
    padding: 6px 12px;
    width: 100%;
}

form textarea {
    width: 790px;
    height: 300px;
    border-radius: 6px;
    margin-top: 36px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    padding: 16px;
    font-family: 'SF Pro Display';
    font-size: 15px;
}

.cin-highlight {
    color: rgba(163, 50, 11, 1);
}