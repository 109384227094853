*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Higher specificity selectors */
div.navbar {
    /* Base navbar styles */
    position: fixed;
    top: 0;
    width: 100%;
    height: 90px;
    border: none;
    background-color: transparent;
    transition: background-color 0.3s ease, color 0.3s ease;
    z-index: 1000;
}

div.navbar.active {
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(20px);
    border: none;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.05);
}

div.navbar a.nav-link {
    color: rgba(255, 255, 255, 0.8);
    transition: color 0.3s ease;
}

div.navbar.active a.nav-link {
    color: #1a1a1a;
}

nav {
    padding: 24px 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100vw;
}

a {
    font-family: 'Inter';
    font-weight: 500;
    letter-spacing: -0.23px;
    font-size: 16px;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.mid-nav {
    display: flex;
    flex-direction: row;
    gap: 24px;
    justify-content: center;
    align-items: center;
}

.nav-link {
    color: rgba(255, 255, 255, 0.80);
    border-radius: 3px;
    background-color: rgba(41, 11, 0, 0);
    transition: all 0.3s ease;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.6px;
    line-height: 140%;
    z-index: 1001;
}

.nav-contact {
    padding: 8px 16px;
    border-radius: 3px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.6px;
    background-color: rgba(41, 11, 0, 1);
    color: #f8f8f8;
    margin: 0;
    line-height: 140%;
    transition: all 0.3s ease;
    cursor: pointer;
}

.nav-contact:hover {
    background-color: rgba(163, 50, 11, 1);
}

.ss-bttn {
    display: flex;
    flex-direction: row;
    gap: 6px;
}

.login {
    padding: 8px 16px;
    border-radius: 3px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.6px;
    background-color: white;
    color: rgba(41, 11, 0, 1);
    margin: 0;
    line-height: 140%;
    transition: all 0.3s ease;
    cursor: pointer;
}

.mid-nav a {
    position: relative;
    padding-bottom: 6px;
    color: rgba(163, 50, 11, 1);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    transition: color 0.3s ease;
}

.mid-nav a::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 1px;
    /* Initial border thickness */
    background-color: rgba(163, 50, 11, 1);
    transition: width 0.3s ease;
}

.nav-link:hover::after {
    width: 100%;
    /* Expands the bottom border smoothly */
}