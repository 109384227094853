*,
*::before,
*::after {
    box-sizing: border-box;
}

.home-wrapper {
    /* Optional: remove redundant overflow styles */
    overflow: visible;
    /* Let elements decide their overflow */
}

.hero-container {
    width: 100vw;
    height: 100vh;
}

.hero-wrapper {
    width: 100vw;
    overflow: hidden;
    /* Full viewport width */
    height: 100vh;
    /* Full viewport height */
    padding: 6px;
    /* Add padding inside the container */
    border-radius: 6px;
    box-sizing: border-box;
    /* Ensures padding is included in the element's size */
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
}

.hero-text {
    position: absolute;
    bottom: 84px;
    left: 84px;
}

.hero-h1 {
    color: white;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 46px;
    line-height: 140%;
    letter-spacing: -2.24px;
    margin-bottom: 36px;
}

.hero-cta {
    padding: 12px 24px;
    color: white;
    background-color: rgba(163, 50, 11, 1);
    font-weight: 500;
    font-family: 'Inter';
    line-height: 140%;
    letter-spacing: -1.2px;
    border-radius: 3px;
    font-size: 20px;
    transition: all 0.3s ease;
    display: inline-flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    justify-content: center;
}

.hero-cta svg {
    display: none;
    transition: all 0.3s ease;
}

.hero-cta:hover {
    background-color: rgba(60, 15, 0, 1);
}

.hero-cta:hover svg {
    display: block;
}

.sub-hero {
    display: flex;
    flex-direction: row;
    margin-top: 146px;
    width: 100vw;
    height: 100vh;
    justify-items: center;
    align-items: center;
}

.sub-hero-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 96px;
}

.sub-l-hero {
    width: 790px;
    height: 420px;
    border-radius: 6px;
    overflow: hidden;
}

.sub-r-hero {
    display: inline-block;
    flex-direction: column;
    gap: 36px;
}

.sub-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 20px;
}

.sub-sub-p {
    font-size: 20px;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: -0.54px;
    font-family: 'SF Pro Display';
    text-align: center;
    margin: 0;
    padding: 0;
    color: rgba(163, 50, 11, 1);
}

.sub-h2 {
    font-size: 42px;
    font-weight: 500;
    line-height: 1.15;
    letter-spacing: -1.28px;
    text-align: center;
    color: rgba(41, 11, 0, 1);
    width: 60%;
    font-family: 'SF Pro Display';
    margin: 0;
    padding: 0;
}

.sub-cta {
    padding: 12px 24px;
    color: white;
    background-color: rgba(163, 50, 11, 1);
    font-weight: 500;
    font-family: 'Inter';
    line-height: 140%;
    letter-spacing: -1.2px;
    border-radius: 3px;
    font-size: 20px;
    transition: all 0.3s ease;
    display: inline-flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    justify-content: center;
    margin-top: 36px;
    transition: all 0.3s ease;
}

.sub-cta svg {
    width: 20px;
    transform: translateX(0);
    transition: all 0.3s ease;
}

.sub-cta:hover svg {
    transform: translateX(6px);
}

.sub-cta:hover {
    background-color: rgba(60, 15, 0, 1);
}

.scroll-section {
    position: relative;
    margin-top: 146px;
}

.scroll-video-wrap {
    position: sticky;
    height: 100vh;
    inset: 0;
    z-index: -1;
    top: 0;
    width: 100%;
}

.video-tag {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: center;
}

.scroll-content {
    background-color: rgba(0, 0, 0, 0.3);
}

.overlay-video {
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 1);
}

.commit-section {
    margin-top: 146px;
    height: 100vh;
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.commit-title {
    font-size: 36px;
    font-family: 'SF Pro Display';
    font-weight: 500;
    text-align: left;
    color: rgba(41, 11, 0, 1);
    letter-spacing: -0.56px;
    margin: 0;
}

.card-wrapper-com {
    display: flex;
    flex-direction: row;
    gap: 24px;
    width: 80%;
    justify-content: center;
    align-items: center;
}

.card-com {
    display: flex;
    gap: 72px;
    border-radius: 6px;
    flex-direction: column;
    justify-content: flex-end;
    align-content: flex-start;
    width: 60%;
    padding: 36px;
    background-color: rgba(60, 15, 0, 0.08);
}

.top-title-com {
    font-size: 24px;
    font-weight: 500;
    font-family: 'SF Pro Display';
    line-height: 140%;
    margin: 0;
    padding: 0;
    color: rgba(41, 11, 0, 1);
    letter-spacing: -0.56;
}

.bttm-title-com {
    font-size: 16px;
    font-weight: 400;
    font-family: 'SF Pro Display';
    line-height: 140%;
    margin: 0;
    padding: 0;
    color: rgba(41, 11, 0, 0.9);
    letter-spacing: -0.48px;
}

.bttn-container-com {
    display: inline-block;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
}

.com-bttn {
    color: rgba(163, 50, 11, 1);
    font-weight: 500;
    font-family: 'SF Pro Display';
}

.com-bttn svg {
    width: 16px;
}

/* button animation */

.bttn-container-com a {
    position: relative;
    padding-bottom: 6px;
    color: rgba(163, 50, 11, 1);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    transition: color 0.3s ease;
}

.bttn-container-com a::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 1px;
    /* Initial border thickness */
    background-color: rgba(163, 50, 11, 1);
    transition: width 0.3s ease;
}

.com-bttn:hover::after {
    width: 100%;
    /* Expands the bottom border smoothly */
}

.card-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.commit-tt-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 72px;
    gap: 16px;
    width: 80%;
}

.commit-p {
    font-family: 'Inter';
    font-size: 20px;
    text-align: left;
    color: rgba(41, 11, 0, 0.8);
    width: 50%;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.86px;
    margin: 0;
}

.solutions-section {
    margin-top: 100px;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.solutions-wrap {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    width: 80%;
    height: 60%;
    float: inline-start;
    padding: 6px;
    background-color: rgba(41, 11, 0, 0.08);
    border-radius: 6px;
}

.solutions-txt {
    height: 100%;
    width: 100%;
    padding: 0 86px 0;
    align-items: flex-start;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.solutions-txt h2 {
    font-size: 36px;
    font-weight: 500;
    font-family: 'SF Pro Display';
    letter-spacing: -1px;
    line-height: 140%;
    text-align: left;
    color: rgba(41, 11, 0, 1);
    margin: 0;
}

.solutions-txt h3 {
    font-size: 20px;
    font-weight: 400;
    font-family: 'Inter';
    letter-spacing: -0.76px;
    line-height: 160%;
    text-align: left;
    margin: 0;
    color: rgba(41, 11, 0, 0.8);
    margin-top: 36px;
    margin-bottom: 36px;
}

.solution-img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 0;
}

.solution-img img {
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.sol-cta {
    font-weight: 500;
    font-family: 'SF Pro Display';
    font-size: 18px;
    letter-spacing: -0.26px;
    line-height: 140%;
    color: rgba(163, 50, 11, 1);
}

/* button animation */

.solutions-txt a {
    position: relative;
    padding-bottom: 6px;
    color: rgba(163, 50, 11, 1);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    transition: color 0.3s ease;
}

.solutions-txt a::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 1px;
    /* Initial border thickness */
    background-color: rgba(163, 50, 11, 1);
    transition: width 0.3s ease;
}

.sol-cta:hover::after {
    width: 100%;
    /* Expands the bottom border smoothly */
}