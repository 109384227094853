.pricing-hero {
    height: calc(100vh - 80px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 10%;
    width: 700px;
}

.pricing-hero h2 {
    font-size: 36px;
    font-weight: 500;
    font-family: 'SF Pro Display';
    margin: 0;
    line-height: 140%;
    letter-spacing: -0.56px;
    margin-bottom: 20px;
    color: rgba(60, 15, 0, 1);
}

.pricing-hero h3 {
    font-size: 20px;
    font-weight: 400;
    font-family: 'SF Pro Display';
    margin: 0;
    line-height: 140%;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 36px;
}

.hero-cta-pricing {
    padding: 12px 24px;
    color: white;
    background-color: rgba(163, 50, 11, 1);
    font-weight: 500;
    font-family: 'Inter';
    line-height: 140%;
    letter-spacing: -1.2px;
    border-radius: 3px;
    font-size: 20px;
    transition: all 0.3s ease;
    display: inline-flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    justify-content: center;
}

.hero-cta-pricing:hover {
    background-color: rgba(60, 15, 0, 1);
}

.pricing-plans-wrapper {
    margin-top: 146px;
    margin-left: 10%;
    margin-right: 10%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.plans-title {
    font-size: 36px;
    text-align: left;
    font-family: 'SF Pro Display';
    letter-spacing: -0.56px;
    font-weight: 500;
    margin: 0;
    width: 100%;
    margin-bottom: 72px;
    color: rgba(60, 15, 0, 1);
}

.plan-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    padding-top: 36px;
    padding-bottom: 36px;
    padding-left: 12px;
    padding-right: 12px;
}

.plan-wrap-sec {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    padding-top: 36px;
    padding-bottom: 36px;
    padding-left: 12px;
    padding-right: 12px;
}

.plan-left {
    width: 35%;
}

.plan-left h2 {
    font-family: 'SF Pro Display';
    font-weight: 500;
    font-size: 24px;
    margin: 0;
    letter-spacing: -0.56px;
}

.plan-left h3 {
    font-family: 'SF Pro Display';
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    margin: 0;
    letter-spacing: -0.26px;
    margin-top: 36px;
    color: rgba(0, 0, 0, 0.8);
}

.plan-right {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-end;
    justify-content: flex-end;
}

.plan-right h3 {
    font-size: 20px;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: -0.26px;
    margin: 0;
    color: rgba(163, 50, 11, 1);
}

.plan-left a {
    position: relative;
    padding-bottom: 6px;
    color: rgba(163, 50, 11, 1);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    transition: color 0.3s ease;
    margin-top: 36px;
}

.plan-left a::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 1px;
    /* Initial border thickness */
    background-color: rgba(163, 50, 11, 1);
    transition: width 0.3s ease;
}

.pricing-cta:hover::after {
    width: 100%;
    /* Expands the bottom border smoothly */
}

.industry-wrapper-pricing {
    width: 100%;
    margin-top: 246px;
    margin-bottom: 246px;
    justify-content: center;
    align-items: center;
    padding-left: 10%;
    padding-right: 10%;
}

.industry-top-pricing {
    display: flex;
    margin-bottom: 72px;
}

.industry-top-pricing h2 {
    font-size: 36px;
    font-weight: 500;
    font-family: 'SF Pro Display';
    letter-spacing: -0.86px;
    color: rgba(41, 11, 0, 1);
    line-height: 140%;
    margin: 0;
}

.industry-slide {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 6px;
    justify-content: center;
    align-items: center;
}

.industry-card-pricing {
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    background-color: rgba(60, 15, 0, 0.08);
    padding: 36px;
    display: flex;
    flex-direction: column;
    gap: 64px;
}

.in-card-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.in-card-title {
    font-size: 24px;
    font-weight: 500;
    font-family: 'SF Pro Display';
    letter-spacing: -0.86px;
    color: rgba(41, 11, 0, 1);
    line-height: 140%;
    margin: 0;
}

.in-card-p {
    font-size: 20px;
    font-weight: 400;
    font-family: 'SF Pro Display';
    letter-spacing: -0.56px;
    color: rgba(41, 11, 0, 1);
    line-height: 140%;
    margin: 0;
}